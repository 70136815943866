<template>
  <div
    :class="
      dateSelectedFinal
        ? 'w-full pt-8 px-3 md:px-0 grid grid-cols-1 md:gap-6'
        : 'w-full min-height pt-8 px-3 md:px-0 grid grid-cols-1 md:grid-cols-2 md:gap-6'
    "
  >
    <div v-if="dateSelectedFinal" class="flex flex-col">
      <transition name="fadeHeightSecond" mode="out-in">
        <div
          class="text-left mt-1 mb-2 text-md text-gray-900"
          v-if="dateSelectedFinal && !isauthenticated"
        >
          <strong
            >Ingresa tu correo y contraseña para confirmar la reserva de
            hora.</strong
          >
          <p>
            Si es primera vez que reservas una hora en nuestra plataforma,
            <a
              class="font-bold text-gray-800 hover:text-gray-600 cursor-pointer underline"
              @click.prevent="
                () => {
                  $router.push({ path: '/login', query: { mode: 'register' } });
                }
              "
              >crea tu cuenta aquí</a
            >
          </p>
        </div>
      </transition>
      <transition name="fadeHeightSecond" mode="out-in">
        <div
          v-if="dateSelectedFinal && !isauthenticated && !login_bool"
          class="bg-white overflow-hidden mb-5 border border-gray-200 flex flex-col justify-start items-center w-full rounded-md p-5"
        >
          <div class="w-full">
            <div class="w-full grid grid-cols-6 gap-y-1 gap-x-2">
              <label
                class="col-span-6  block text-2xl text-left font-bold text-gray-700"
              >
                Registro
              </label>
              <label
                class="col-span-6 block text-sm text-left font-medium text-gray-500"
              >
                Al crear una cuenta. no tendrás que completar nuevamente este
                formulario para reservar tus próximas consultas.
              </label>
              <transition name="fadeHeight" mode="out-in">
                <alert-box
                  v-if="
                    errorRegister != '' ||
                      (Array.isArray(errorRegister) &&
                        errorRegister.length != 0)
                  "
                  class="col-span-6 login-alert-box"
                  type="danger"
                  :title="''"
                  :errors="errorRegister"
                />
              </transition>
              <input
                v-model="register_data.names"
                name="names"
                type="text"
                autocomplete="names"
                required
                class="col-span-6 md:col-span-3 mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Nombres"
              />
              <input
                v-model="register_data.lastnames"
                name="lastnames"
                type="text"
                autocomplete="lastnames"
                required
                class="col-span-6 md:col-span-3 mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Apellidos"
              />
              <input
                v-model="register_data.rut"
                name="rut"
                type="text"
                autocomplete="rut"
                required
                :class="
                  errorRut
                    ? 'ring-red-500 border-red-500 border-3 bg-red-50'
                    : 'border'
                "
                class="col-span-6 mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="ej: 11111111-1"
              />
              <label
                id="listbox-label"
                class="col-span-6 w-full text-left block text-sm text-left font-medium text-gray-700"
              >
                País
              </label>
              <!-- <label
                class="col-span-6 w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Incidunt adipisci facilis, deleniti repellendus nisi
                voluptatibus.
              </label> -->
              <div class="col-span-6 mb-2 w-full relative">
                <button
                  @click="
                    () => {
                      countrySelector = !countrySelector;
                    }
                  "
                  v-click-outside="
                    () => {
                      countrySelector = false;
                    }
                  "
                  type="button"
                  class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                  aria-haspopup="listbox"
                  aria-expanded="true"
                  aria-labelledby="listbox-label"
                >
                  <span class="flex items-center">
                    <img
                      :src="country.flag"
                      alt=""
                      class="flex-shrink-0 h-6 w-6"
                    />
                    <span class="ml-3 block truncate">
                      ({{ country.dialCode }}) {{ country.name }}
                    </span>
                  </span>
                  <span
                    class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <!-- Heroicon name: solid/selector -->
                    <svg
                      class="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </button>

                <transition
                  enter-active-class=""
                  enter-class=""
                  enter-to-class=""
                  leave-active-class="transition ease-in duration-100"
                  leave-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ul
                    v-if="countrySelector"
                    class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    tabindex="-1"
                    role="listbox"
                    aria-labelledby="listbox-label"
                    aria-activedescendant="listbox-option-3"
                  >
                    <li
                      v-for="(countryElement, countryIndex) in countries"
                      :key="countryIndex"
                      class="cursor-default select-none relative py-2 pl-3 pr-9"
                      :class="
                        countryElement.isoCode === country.isoCode
                          ? 'bg-gray-200'
                          : 'text-gray-900'
                      "
                      id="listbox-option-0"
                      role="option"
                      @click="
                        () => {
                          country = countryElement;
                        }
                      "
                    >
                      <div class="flex items-center">
                        <img
                          :src="countryElement.flag"
                          alt=""
                          class="flex-shrink-0 h-6 w-6"
                        />
                        <span
                          class="ml-3 block truncate"
                          :class="
                            countryElement.isoCode === country.isoCode
                              ? 'font-semibold'
                              : 'font-normal'
                          "
                        >
                          ({{ countryElement.dialCode }})
                          {{ countryElement.name }}
                        </span>
                      </div>

                      <span
                        class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4"
                        :class="
                          countryElement.isoCode === country.isoCode
                            ? 'text-green-500'
                            : 'hidden'
                        "
                      >
                        <!-- Heroicon name: solid/check -->
                        <svg
                          class="h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </li>

                    <!-- More items... -->
                  </ul>
                </transition>
              </div>
              <label
                class="col-span-6 w-full text-left block text-sm text-left font-medium text-gray-700"
              >
                Número teléfono
              </label>
              <div class="col-span-6 mb-2 w-full flex rounded-md shadow-sm">
                <span
                  class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"
                >
                  {{ country.dialCode }}
                </span>
                <input
                  v-model="register_data.phone"
                  name="rut"
                  type="text"
                  autocomplete="phone"
                  required
                  class="focus:ring-green-500 focus:border-green-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                  placeholder="ej: 912345678"
                />
              </div>
              <div class="col-span-6 mb-2 w-full flex rounded-md shadow-sm">
                <span
                  class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"
                >
                  {{ country.dialCode }}
                </span>
                <input
                  v-model="register_data.phone_confirmation"
                  name="rut"
                  type="text"
                  autocomplete="phone"
                  required
                  class="focus:ring-green-500 focus:border-green-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                  placeholder="Confirmar Número teléfono"
                />
              </div>
              <input
                v-model="register_data.email"
                name="email-register"
                type="email"
                autocomplete="email-register"
                required
                :class="
                  errorEmailRegister
                    ? 'ring-red-500 border-red-500 border-3 bg-red-50'
                    : 'border'
                "
                class="col-span-6 mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Correo electrónico"
              />
              <label
                class="col-span-6 block text-sm text-left font-medium text-gray-700"
              >
                Fecha de nacimiento
                <span class="font-xs font-light mr-2" v-if="age != null"
                  >/</span
                >
                <span class="font-bold" v-if="age != null">{{ age }}</span>
              </label>
              <div class="mb-2 col-span-6 grid grid-cols-3 gap-2">
                <select
                  v-model="form.day"
                  class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                >
                  <option selected disabled :value="''">Día</option>
                  <option
                    v-for="(day, dayIndex) in days_list"
                    :key="dayIndex"
                    >{{ day }}</option
                  > </select
                ><select
                  v-model="form.month"
                  class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                >
                  <option selected disabled :value="''">Mes</option>
                  <option
                    v-for="(month, monthIndex) in months"
                    :key="monthIndex"
                    :value="monthIndex + 1"
                    >{{ month }}</option
                  > </select
                ><select
                  v-model="form.year"
                  class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                >
                  <option selected disabled :value="''">Año</option>
                  <option
                    v-for="(year, yearIndex) in years_list()"
                    :key="yearIndex"
                    >{{ year }}</option
                  >
                </select>
              </div>
              <label
                class="col-span-6 block text-sm text-left font-medium text-gray-700"
              >
                Dirección de factura
              </label>
              <div
                class="w-full mb-2 col-span-6 grid gap-x-2 grid-cols-1 md:grid-cols-2"
              >
                <select
                  v-model="register_data.region"
                  class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                >
                  <option selected disabled :value="''">Region</option>
                  <option
                    v-for="(region, regionIndex) in regiones"
                    :key="regionIndex"
                    >{{ region.region }}</option
                  >
                </select>
                <select
                  name="comuna"
                  autocomplete="comuna"
                  v-model="register_data.comuna"
                  class="col-span-2 md:col-span-1 mt-2 md:mt-0 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                >
                  <option selected disabled :value="''">Comuna</option>
                  <option
                    v-for="(comuna, comunaIndex) in comunas"
                    :key="comunaIndex"
                    >{{ comuna }}</option
                  >
                </select>
                <input
                  v-model="register_data.address"
                  name="rut"
                  type="text"
                  autocomplete="address"
                  required
                  class="col-span-2 mt-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                  placeholder="Dirección"
                />
              </div>
              <label
                for="country"
                class="col-span-6 block text-left text-sm font-medium text-gray-700"
                >Previsión de salud</label
              >
              <select
                name="prevision"
                autocomplete="prevision"
                v-model="form.prevision"
                class="col-span-6 mb-2 block w-full py-2 px-3 disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
              >
                <option selected disabled :value="null">Seleccionar</option>
                <option
                  v-for="(prevision, previsionIndex) in previsions"
                  :key="previsionIndex"
                  >{{ prevision }}</option
                >
              </select>
              <label
                class="col-span-6 block text-sm text-left font-medium text-gray-700"
              >
                Crea una contraseña
              </label>
              <input
                v-model="register_data.password"
                name="password-register"
                type="password"
                autocomplete="current-password"
                required
                class="col-span-6 mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Contraseña"
              />
              <input
                v-model="register_data.confirmpassword"
                name="confirm-password"
                type="password"
                autocomplete="confirm-password"
                required
                class="col-span-6 mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Confirma la contraseña"
                @keyup.enter="register_submit()"
              />
              <div class="col-span-6 login-remember">
                <div class="w-full">
                  <input-checkbox
                    placeholder="Acepto"
                    link="Términos y Condiciones"
                    url="/terminosycondiciones"
                    class="no-margin"
                    @checkbox="
                      value => {
                        register_data.terms = value;
                      }
                    "
                  />
                </div>
              </div>
              <div class="col-span-6 login-remember">
                <div class="w-full">
                  <div
                    class="d-flex flex-row justify-center items-center text-md mt-4"
                  >
                    <a
                      class="underline font-bold text-green-600 hover:text-green-800 visited:text-green-700 cursor-pointer"
                      @click="
                        () => {
                          login_bool = !login_bool;
                        }
                      "
                      >Ya tengo una cuenta</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="fadeHeightSecond" mode="out-in">
        <div
          v-if="dateSelectedFinal && !isauthenticated && login_bool"
          class="bg-white overflow-hidden mb-5 border border-gray-200 flex flex-col justify-start items-center w-full rounded-md p-5"
        >
          <div class="w-full">
            <div class="w-full grid grid-cols-6 gap-y-1 gap-x-2">
              <label
                class="col-span-6 block text-2xl text-left font-bold text-gray-700"
              >
                Datos del paciente
              </label>
              <transition name="fadeHeight" mode="out-in">
                <alert-box
                  v-if="
                    errorLogin != '' ||
                      (Array.isArray(errorLogin) && errorLogin.length != 0)
                  "
                  class="col-span-6 login-alert-box mb-3"
                  type="danger"
                  :title="''"
                  :errors="errorLogin"
                />
              </transition>
              <input
                v-model="login_data.email"
                name="email"
                type="email"
                autocomplete="email"
                required
                :class="
                  errorEmailLogin
                    ? 'ring-red-500 border-red-500 border-3 bg-red-50'
                    : 'border'
                "
                class="mb-2 w-full col-span-6 appearance-none relative block px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Correo electrónico"
              />
              <input
                v-model="login_data.password"
                name="password-login"
                type="password"
                autocomplete="password"
                required
                class="col-span-6 mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Contraseña"
              />
              <div class="col-span-6 login-remember">
                <div class="w-full">
                  <div class="login-forgot">
                    <router-link class="login-link" to="/forgot"
                      >¿Olvidaste tu contraseña?</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-span-6 login-remember">
                <div class="w-full">
                  <div
                    class="d-flex flex-row justify-center items-center text-md"
                  >
                    <a
                      class="underline font-bold text-green-600 hover:text-green-800 visited:text-green-700 cursor-pointer"
                      @click="
                        () => {
                          login_bool = !login_bool;
                        }
                      "
                      >No tengo cuenta</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="fadeHeightSecond" mode="out-in">
        <div
          v-if="dateSelectedFinal && isauthenticated"
          class="bg-white overflow-hidden mt-5 mb-5 md:mb-0 border border-gray-200 flex flex-col justify-start items-center w-full rounded-md p-5"
        >
          <div class="w-full">
            <div class="w-full grid grid-cols-6 gap-y-1 gap-x-2">
              <label
                class="col-span-6 block text-2xl text-left font-bold text-gray-700"
              >
                Datos del paciente
              </label>
              <div
                class="w-full col-span-6 grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-2"
              >
                <div class="flex flex-col w-full justify-center items-start">
                  <span class="text-sm text-gray-600">Nombre paciente</span>
                  <div
                    class="bg-gray-200 w-full rounded-md py-2 text-left px-4"
                  >
                    {{ capitalize(user.names) }}
                    {{ capitalize(user.lastnames) }}
                  </div>
                </div>
                <div class="flex flex-col w-full justify-center items-start">
                  <span class="text-sm text-gray-600">Rut</span>
                  <div
                    class="bg-gray-200 w-full rounded-md py-2 text-left px-4"
                  >
                    {{ user.rut }}
                  </div>
                </div>
                <div class="flex flex-col w-full justify-center items-start">
                  <span class="text-sm text-gray-600">Email</span>
                  <div
                    class="bg-gray-200 w-full rounded-md py-2 text-left px-4"
                  >
                    {{ user.email }}
                  </div>
                </div>
                <div class="flex flex-col w-full justify-center items-start">
                  <span class="text-sm text-gray-600">Teléfono</span>
                  <div
                    class="bg-gray-200 w-full rounded-md py-2 text-left px-4"
                  >
                    {{ user.phone }}
                  </div>
                </div>
                <div
                  class="col-span-2 flex flex-row justify-end items-center pr-5"
                >
                  <button
                    class="underline text-sm text-green-600 hover:text-green-800 visited:text-green-700 cursor-pointer"
                    @click.prevent="
                      () => {
                        $router.push({ path: '/profile' });
                      }
                    "
                  >
                    Cambiar datos de contacto
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="!dateSelectedFinal" class="flex flex-col">
      <div
        class="bg-white rounded-md border border-gray-200 p-3 flex flex-col md:flex-row justify-center md:justify-start items-center mb-5"
      >
        <div
          class="bg-cover bg-center overflow-hidden h-32 w-32 rounded-full md:rounded-md mb-2 md:mb-0 border border-gray-200 md:mr-4"
          :style="
            doctor && doctor.doctor && doctor.doctor.avatar
              ? 'background-image: url(' + doctor.doctor.avatar_url + ')'
              : ''
          "
          :class="
            doctor && doctor.profile
              ? ''
              : 'flex justify-center items-center bg-gray-200'
          "
          alt=""
        >
          <span
            class="font-bold text-2xl text-gray-600"
            v-if="doctor && doctor.doctor && !doctor.doctor.avatar"
            >{{ doctor.names[0] }} {{ doctor.lastnames[0] }}</span
          >
        </div>
        <div
          class="flex flex-col justify-center md:justify-start items-center md:items-start"
        >
          <span
            class="text-xl md:text-2xl text-center md:text-left text-gray-900 font-bold"
            v-if="doctor"
            >{{ capitalize(doctor.names) }}
            {{ capitalize(doctor.lastnames) }}</span
          >
          <span
            class="h-8 mb-2 bg-gray-300 rounded w-48 animate-pulse"
            v-else
          ></span>
          <span
            class="text-md md:text-xl text-gray-900 font-medium text-center md:text-left"
            v-if="doctor && doctor.doctor"
            >{{ doctor.doctor.specialty }}</span
          >
          <span
            class="h-4 mb-2 bg-gray-300 rounded w-40 animate-pulse"
            v-else
          ></span>
        </div>
      </div>
      <!-- <div class="flex flex-col justify-start items-start">
        <small class="text-sm font-bold text-gray-500">Especialidad</small>
        <span class="text-3xl text-left text-gray-900 font-medium"
          >Psiquiatría</span
        >
      </div> -->
      <div
        class="bg-white rounded-md border border-gray-200 p-3 flex flex-col md:flex-row justify-center md:justify-start items-center mt-2 mb-3"
      >
        <div
          class="flex flex-col justify-center md:justify-start items-center md:items-start"
        >
          <div class="grid grid-cols-12 gap-4">
            <svg
              v-if="doctor && doctor.doctor && doctor.doctor.address"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-gray-700 mr-1 mb-2 col-span-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                clip-rule="evenodd"
              />
            </svg>
            <span
              v-if="doctor && doctor.doctor && doctor.doctor.address"
              class="col-span-10 text-md text-left text-gray-700 font-medium"
              >{{ doctor.doctor.address }}</span
            >
            <svg
              v-if="doctor && doctor.doctor && doctor.doctor.prices"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-gray-700 mr-1 col-span-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                clip-rule="evenodd"
              />
            </svg>
            <span
              v-if="doctor && doctor.doctor && doctor.doctor.prices"
              style="white-space: pre-line"
              class="col-span-10 text-md text-left text-gray-700 font-medium"
              >{{ doctor.doctor.prices }}</span
            >
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-start items-center mb-2">
        <!-- <div
          v-if="doctor && doctor.doctor && doctor.doctor.logo"
          class="bg-cover bg-center overflow-hidden h-12 w-12 border border-gray-200 rounded-md mr-2"
          :style="'background-image: url(' + doctor.doctor.logo_url + ')'"
          alt=""
        ></div> -->

        <a
          v-if="doctor && doctor.doctor && doctor.doctor.linkedin"
          :href="doctor.doctor.linkedin"
          class="underline flex flex-row justify-start items-center font-bold text-green-600 hover:text-green-800 visited:text-green-700"
          rel="nofollow"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            class="w-10 mr-2"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 112.196 112.196"
            style="enable-background:new 0 0 112.196 112.196;"
            xml:space="preserve"
          >
            <g>
              <circle
                style="fill:#007AB9;"
                cx="56.098"
                cy="56.097"
                r="56.098"
              />
              <g>
                <path
                  style="fill:#F1F2F2;"
                  d="M89.616,60.611v23.128H76.207V62.161c0-5.418-1.936-9.118-6.791-9.118    c-3.705,0-5.906,2.491-6.878,4.903c-0.353,0.862-0.444,2.059-0.444,3.268v22.524H48.684c0,0,0.18-36.546,0-40.329h13.411v5.715    c-0.027,0.045-0.065,0.089-0.089,0.132h0.089v-0.132c1.782-2.742,4.96-6.662,12.085-6.662    C83.002,42.462,89.616,48.226,89.616,60.611L89.616,60.611z M34.656,23.969c-4.587,0-7.588,3.011-7.588,6.967    c0,3.872,2.914,6.97,7.412,6.97h0.087c4.677,0,7.585-3.098,7.585-6.97C42.063,26.98,39.244,23.969,34.656,23.969L34.656,23.969z     M27.865,83.739H41.27V43.409H27.865V83.739z"
                />
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
          Ver perfil de LinkedIn</a
        >
      </div>
      <div
        class="bg-white rounded-md border border-gray-200 p-3 flex flex-col md:flex-row justify-center md:justify-start items-center mt-2 mb-3"
      >
        <p
          v-if="doctor && doctor.doctor && doctor.doctor.description"
          style="white-space: pre-line"
          class="text-md font-light text-left"
        >
          {{ doctor.doctor.description }}
        </p>
        <p
          v-else
          style="white-space: pre-line"
          class="text-md font-light text-left"
        >
          Sin descripción
        </p>
      </div>
    </div>
    <div class="flex flex-col justify-start items-start">
      <transition name="fadeHeightSecond" mode="out-in">
        <div
          class="bg-white mb-5 border border-gray-200 flex flex-col justify-start items-center w-full rounded-md p-5"
          v-if="dateSelectedFinal == null"
        >
          <div
            class="w-full border border-gray-200 overflow-hidden rounded-md p-3 mb-3"
          >
            <div class="w-full flex flex-row justify-between items-center mb-3">
              <button
                @click="leftArrow()"
                :disabled="previousMonthIsBlocked"
                :class="
                  !previousMonthIsBlocked
                    ? 'cursor-pointer hover:bg-gray-800'
                    : 'cursor-not-allowed'
                "
                class="disabled:opacity-50 group relative flex justify-center text-sm font-medium rounded-md text-gray-50 bg-gray-700 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8 text-gray-50"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <button
                @click="
                  () => {
                    calendarType = 2;
                    daySelected = null;
                    monthSelected = null;
                  }
                "
                class="group relative flex justify-center py-2 px-8 text-sm border border-gray-300 text-gray-700 hover:text-gray-800 font-bold rounded-md text-gray-50 bg-gray-200 hover:bg-gray-300 focus:outline-none"
              >
                <span v-if="calendarType == 1">{{ monthyearSelected }}</span>
                <span v-if="calendarType == 2">{{ yearSelected }}</span>
              </button>
              <button
                @click="rightArrow()"
                class="cursor-pointer group relative flex justify-center text-sm font-medium rounded-md text-gray-50 bg-gray-700 hover:bg-gray-800 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8 text-gray-50"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div
              v-if="calendarType == 1"
              class="text-gray-900 font-bold text-sm md:text-base grid grid-cols-7 gap-1 md:gap-3 mb-2"
            >
              <span>Lu</span>
              <span>Ma</span>
              <span>Mi</span>
              <span>Ju</span>
              <span>Vi</span>
              <span>Sa</span>
              <span>Do</span>
            </div>
            <transition name="fadeHeightNormal" mode="out-in">
              <div v-if="calendarType == 1">
                <div
                  v-for="(week, weekIndex) in month"
                  :key="weekIndex"
                  class="text-gray-100 font-light grid grid-cols-7 gap-1 md:gap-3"
                >
                  <button
                    v-for="(day, dayIndex) in week"
                    :key="dayIndex"
                    :class="
                      day && day.valid && daysArray.includes(day.number)
                        ? daySelected == day.number
                          ? 'bg-green-700 font-bold text-gray-50'
                          : 'hover:bg-gray-100 font-bold text-gray-700'
                        : 'cursor-not-allowed font-light text-gray-300'
                    "
                    class="text-sm md:text-base p-2 rounded-md"
                    @click="selectDay(day)"
                  >
                    <span v-if="day">{{ day.number }}</span>
                  </button>
                </div>
              </div>
            </transition>
            <transition name="fadeHeightNormal" mode="out-in">
              <div
                v-if="calendarType == 1"
                class="mt-2 pt-2 w-full flex flex-col md:flex-row justify-center items-start md:items-center"
              >
                <div
                  class="flex flex-row mb-1 md:mb-0 justify-center items-center mr-3"
                >
                  <div class="h-5 w-5 bg-gray-700 rounded-full mr-2"></div>
                  <span class="text-sm">Con horas</span>
                </div>
                <div
                  class="flex flex-row mb-1 md:mb-0 justify-center items-center"
                >
                  <div class="h-5 w-5 bg-gray-300 rounded-full mr-2"></div>
                  <span class="text-sm">Sin horas</span>
                </div>
              </div>
            </transition>
            <transition name="fadeHeightSecond" mode="out-in">
              <div v-if="calendarType == 2">
                <div
                  class="text-gray-100 font-light grid grid-cols-4 gap-1 md:gap-3"
                >
                  <button
                    v-for="(monthshort, monthshortIndex) in monthsShort"
                    :key="monthshortIndex"
                    :disabled="monthshort == null"
                    :class="
                      monthshort
                        ? 'cursor-pointer' +
                          (monthSelected == monthshortIndex + 1
                            ? 'bg-green-700 font-bold text-gray-50'
                            : 'hover:bg-gray-100 font-bold text-gray-700 hover:bg-green-700 hover:text-gray-50')
                        : 'cursor-not-allowed bg-gray-200'
                    "
                    class="disabled:opacity-50 text-sm md:text-base p-2 rounded-md"
                    @click="
                      () => {
                        monthSelected = monthshortIndex + 1;
                        calendarType = 1;
                        daySelected = null;
                      }
                    "
                  >
                    <span v-if="monthshort">{{ monthshort }}</span>
                    <span v-else class="text-gray-200">-</span>
                  </button>
                </div>
              </div>
            </transition>
          </div>
          <div class="text-left text-sm text-gray-700 w-full my-2">
            {{ blocks.length !== 0 ? blocks.length : '' }} Hora{{
              blocks.length == 1 ? '' : 's'
            }}
            disponibles para {{ dateSelectedNoHour }}
          </div>
          <div class="text-left text-sm w-full rounded-md">
            <div
              v-for="(block, blockIndex) in blocks"
              :key="blockIndex"
              class="block w-full mr-0 md:mr-2 mb-2 overflow-hidden border border-gray-200 text-sm font-medium rounded-md text-gray-50 focus:outline-none"
              :class="
                blockSelected && blockSelected.id === block.id
                  ? 'bg-green-600'
                  : ''
              "
            >
              <div class="w-full grid grid-cols-4">
                <span
                  :class="
                    blockSelected && blockSelected.id === block.id
                      ? 'bg-green-600 text-gray-50'
                      : 'text-gray-900'
                  "
                  class="flex flex-col justify-center items-start pl-4 py-2 pr-2"
                >
                  <span class="bg-gray-200 p-4 rounded-md"
                    >{{ formatHour(block.start_date) }} hrs</span
                  >
                  <!-- <div
                    class="flex flex-row"
                    :class="
                      blockSelected && blockSelected.id === block.id
                        ? 'text-gray-50'
                        : 'text-gray-900'
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-3 w-3"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div> -->
                  <!-- <span>{{ formatHour(block.finish_date) }} hrs</span> -->
                </span>
                <span
                  class="flex justify-center items-center py-2 col-span-2"
                  :class="
                    blockSelected && blockSelected.id === block.id
                      ? 'text-gray-100'
                      : 'text-gray-900'
                  "
                >
                  <span>{{ block.text }}</span>
                </span>
                <div class="px-2 py-2 flex justify-center items-center">
                  <button
                    @click="
                      () => {
                        blockSelected = block;
                        selectDate();
                      }
                    "
                    :class="
                      blockSelected && blockSelected.id === block.id
                        ? 'bg-green-800 hover:bg-green-900'
                        : 'bg-green-600 hover:bg-green-800'
                    "
                    class="font-bold text-xs w-full h-full rounded-md text-gray-50"
                  >
                    Reservar
                  </button>
                </div>
              </div>
            </div>
            <transition name="fade" mode="out-in">
              <div
                class="h-12 flex flex-col justify-center items-center"
                v-if="blocks.length == 0 && is_loading_blocks"
              >
                <spinner-component size="20px" color="#ffffff" />
              </div>
              <div
                class="text-center text-md text-gray-700 w-full my-2 border border-gray-200 rounded-md p-3 bg-gray-100"
                v-if="blocks.length == 0 && !is_loading_blocks"
              >
                No hay horas para esta fecha
              </div>
            </transition>
          </div>
        </div>
      </transition>
      <transition name="fadeHeightSecond" mode="out-in">
        <div
          v-if="dateSelectedFinal"
          class="bg-white overflow-hidden mb-5 border border-gray-200 flex flex-col justify-start items-center w-full rounded-md p-5"
        >
          <div class="w-full">
            <label
              class="block text-2xl text-left font-bold text-gray-700 mb-1"
            >
              Método de pago
            </label>
            <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-2">
              <div
                class="p-5 border border-gray-200 rounded-md flex flex-col justify-start items-start"
              >
                <span class="font-bold">Tarifa</span>
                <span class="text-gray-900 text-lg text-left"
                  ><span class="text-md mr-2">Tipo de reserva:</span>
                  <span v-if="blockSelected">
                    <span class="font-bold text-md">{{
                      blockSelected.text
                    }}</span></span
                  >
                  <br />
                  <span class="text-md mr-2">Total:</span>
                  <span v-if="blockSelected">
                    <span class="font-bold">{{
                      calculateBlockPrice(blockSelected)
                    }}</span></span
                  >
                  <span class="font-bold" v-else
                    >No has seleccionado una hora</span
                  ></span
                >
                <button
                  v-if="dateSelectedFinal"
                  class="text-md mt-5 text-green-700 border-b border-green-500 hover:text-green-500 hover:border-green-300"
                  @click="
                    () => {
                      dateSelectedFinal = null;
                      login_bool = true;
                      payment = null;
                    }
                  "
                >
                  Volver a seleccionar fecha
                </button>
              </div>
              <div class="flex flex-col">
                <div
                  class="w-full flex flex-row justify-start items-center p-2 rounded-md cursor-pointer"
                  @click="
                    () => {
                      payment = 'khipu';
                    }
                  "
                  :class="
                    payment == 'khipu'
                      ? 'border border-gray-300 bg-gray-200'
                      : 'border border-gray-200 bg-gray-50 hover:bg-gray-100 hover:border-gray-300'
                  "
                >
                  <img
                    class="bg-cover bg-center overflow-hidden h-20 w-20 rounded-md border border-gray-200 mr-2"
                    src="@/assets/img/logos/khipu.png"
                  />
                  <div
                    class="h-8 w-8 rounded-full border p-2 text-gray-600 mr-2 flex justify-center items-center border-gray-200"
                    :class="payment == 'khipu' ? 'bg-gray-300' : 'bg-gray-300'"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-10 w-10 text-green-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      v-if="payment == 'khipu'"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <span class="text-gray-800 text-md font-semibold">Khipu</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="fadeHeightSecond" mode="out-in">
        <div
          v-if="dateSelectedFinal"
          class="bg-white overflow-hidden mt-2 mb-5 md:mb-0 border border-gray-200 flex flex-col justify-start items-center w-full rounded-md p-5"
        >
          <div class="w-full">
            <div class="w-full grid grid-cols-6 gap-y-1 gap-x-2">
              <label
                class="col-span-6 block text-2xl text-left font-bold text-gray-700"
              >
                Detalles de la Reserva
              </label>
              <div class="col-span-6 flex flex-row justify-start items-center">
                <div class="flex flex-col justify-center items-start">
                  <label
                    class="col-span-6 block text-md text-left font-medium text-gray-800"
                  >
                    Nombre de Profesional: {{ capitalize(doctor.names) }}
                    {{ capitalize(doctor.lastnames) }}
                  </label>
                  <label
                    class="col-span-6 block text-md text-left font-medium text-gray-600"
                  >
                    Especialidad: {{ doctor.doctor.specialty }}
                  </label>
                  <label
                    class="col-span-6 block text-md text-left font-medium text-gray-600"
                  >
                    Modalidad: {{ blockSelected.text }}
                  </label>
                  <label
                    v-if="dateSelectedNoHour"
                    class="col-span-6 block text-md text-left font-medium text-gray-600"
                  >
                    Fecha Atención: {{ dateSelectedNoHour }}
                  </label>
                  <label
                    v-if="dateSelectedHour"
                    class="col-span-6 block text-md text-left font-medium text-gray-600"
                  >
                    Hora Atención: {{ dateSelectedHour }} hrs
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="fadeHeightSecond" mode="out-in">
        <div
          v-if="dateSelectedFinal && false"
          class="bg-white overflow-hidden mb-5 border border-gray-200 flex flex-col justify-start items-center w-full rounded-md p-5"
        >
          <div class="w-full">
            <label class="block text-left text-sm font-medium text-gray-700">
              Deja una nota
            </label>
            <div class="mt-1">
              <textarea
                name="note"
                rows="3"
                v-model="form.note"
                class="shadow-sm resize-none focus:ring-green-500 focus:border-green-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
              ></textarea>
            </div>
          </div>
        </div>
      </transition>
      <transition name="fadeHeightNormal" mode="out-in">
        <div class="w-full flex-col" v-if="dateSelectedFinal">
          <div class="w-full pl-5 mb-4">
            <input-checkbox
              placeholder="Acepto los"
              link="Términos y Condiciones"
              secondplaceholder="y recibir recordatorios de la teleconsulta."
              url="/terminosycondiciones"
              class="no-margin"
              @checkbox="
                value => {
                  termsReservation = value;
                }
              "
            />
          </div>
          <div class="w-full mb-20 flex flex-row justify-center items-center">
            <button
              v-if="dateSelectedFinal"
              :class="
                blockSelected != null && payment != null && termsReservation
                  ? 'cursor-pointer hover:bg-green-600'
                  : 'cursor-not-allowed'
              "
              class="disabled:opacity-50 group relative flex justify-center py-4 px-32 border border-transparent text-sm font-medium rounded-md text-gray-50 bg-green-500 focus:outline-none"
              :disabled="
                blockSelected == null ||
                  payment == null ||
                  termsReservation == false
              "
              @click="
                () => {
                  payment != null
                    ? dateSelectedFinal
                      ? termsReservation
                        ? reservation()
                        : ''
                      : selectDate()
                    : '';
                }
              "
            >
              <span v-if="dateSelectedFinal">Reservar</span>
              <span v-else>Siguiente</span>
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import InputCheckbox from '@/components/form/InputCheckbox.vue';
import Spinner from '@/components/animations/SpinnerReservation.vue';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import regiones from '@/utils/comunas-regiones.json';
import countries from '@/utils/country-codes.json';
import DoctorService from '@/services/doctorService';
import PurchaseService from '@/services/purchaseService';
import ClickOutside from 'vue-click-outside';
import AlertBox from '@/components/form/AlertBox.vue';

export default {
  name: 'reservation',
  components: {
    'spinner-component': Spinner,
    'input-checkbox': InputCheckbox,
    'alert-box': AlertBox,
  },
  data() {
    return {
      termsReservation: false,
      doctor: null,
      regiones: regiones,
      countries: countries,
      countrySelector: false,
      payment: null,
      country: {
        name: 'Chile',
        dialCode: '+56',
        isoCode: 'CL',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cl.svg',
      },
      comunas: [],
      yearSelected: moment().year(),
      monthSelected: moment().month() + 1,
      daySelected: moment().date(),
      blockSelected: null,
      dateSelectedFinal: null,
      calendarType: 1,
      years: [moment().year()],
      blocks: [],
      is_loading_blocks: false,
      previsions: [
        'Fondo Nacional De Salud (Fonasa)',
        'Isalud Isapre De Codelco',
        'Isapre Banmédica',
        'Isapre Colmena',
        'Isapre Consalud',
        'Isapre Cruz Blanca',
        'Isapre Cruz Del Norte',
        'Isapre Fundación Banco Estado',
        'Isapre Nueva Másvida',
        'Isapre Vida Tres',
        'Particular',
        'Isapre Esencial',
      ],
      // login or register
      login_bool: true,
      // reservation form
      form: {
        prevision: null,
        birthdate: null,
        day: '',
        month: '',
        year: '',
        note: '',
      },
      // login data
      login_data: {
        email: '',
        password: '',
        remember: false,
      },
      // register data
      register_data: {
        names: '',
        lastnames: '',
        rut: '',
        email: '',
        phone: '',
        phone_confirmation: '',
        password: '',
        confirmpassword: '',
        terms: false,
        region: '',
        comuna: '',
        address: '',
      },
      errorEmailRegister: false,
      errorEmailLogin: false,
      errorRut: false,
      show_pass: false,
      errorRegister: '',
      errorLogin: '',
      canSubmitRegister: true,
      canSubmitLogin: true,
      daysArray: [],
    };
  },
  created() {
    for (let i = 0; i < 10; i++) {
      this.years.push(this.years[this.years.length - 1] + 1);
    }
    this.change(true);
  },
  methods: {
    ...mapActions('loading', ['change']),
    ...mapActions('authentication', ['login', 'register']),
    capitalize(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
    },
    changeDaysArray() {
      if (this.doctor) {
        this.daysArray = [];
        DoctorService.getSchedule({
          id: this.doctor.id,
          month: this.monthSelected,
          year: this.yearSelected,
        })
          .then(response => {
            this.daysArray = response.data;
          })
          .catch(() => {
            console.log('fail to get days array');
          });
      }
    },
    calculateBlockPrice(block) {
      let chileanPeso = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      let temp_start, temp_finish, duration, amount;
      if (block.full_rate) {
        return chileanPeso.format(block.full_rate).replace(/,/g, '.');
      } else if (block.rate) {
        temp_start = moment(block.start_date, 'YYYY-MM-DD HH:mm');
        temp_finish = moment(block.finish_date, 'YYYY-MM-DD HH:mm');
        duration = moment.duration(temp_finish.diff(temp_start));
        amount = duration.asHours();
        return chileanPeso.format(amount * block.rate).replace(/,/g, '.');
      } else {
        temp_start = moment(block.start_date, 'YYYY-MM-DD HH:mm');
        temp_finish = moment(block.finish_date, 'YYYY-MM-DD HH:mm');
        duration = moment.duration(temp_finish.diff(temp_start));
        amount = duration.asHours();
        return chileanPeso
          .format(amount * this.doctor.doctor.rate)
          .replace(/,/g, '.');
      }
    },
    validateEmail: email => {
      const regex = /\S+@\S+\.\S+/;
      return regex.test(email);
    },
    validateRut: rut => {
      var Fn = {
        // Valida el rut con su cadena completa "XXXXXXXX-X"
        validaRut: function(rutCompleto) {
          if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
          var tmp = rutCompleto.split('-');
          var digv = tmp[1];
          var rut = tmp[0];
          if (digv == 'K') digv = 'k';
          return Fn.dv(rut) == digv;
        },
        dv: function(T) {
          var M = 0,
            S = 1;
          for (; T; T = Math.floor(T / 10))
            S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
          return S ? S - 1 : 'k';
        },
      };
      return Fn.validaRut(rut);
    },
    formatHour(date) {
      let temp = moment(date, 'YYYY-MM-DD HH:mm');
      return temp.format('HH:mm');
    },
    getSchedule() {
      this.blocks = [];
      this.is_loading_blocks = true;
      DoctorService.schedule({
        doctor: this.doctor.doctor.name,
        date:
          (this.daySelected > 9
            ? this.daySelected.toString()
            : '0' + this.daySelected.toString()) +
          '/' +
          (this.monthSelected > 9
            ? this.monthSelected.toString()
            : '0' + this.monthSelected.toString()) +
          '/' +
          this.yearSelected.toString(),
      })
        .then(response => {
          this.blocks = response.data;
          this.is_loading_blocks = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    years_list() {
      const years = back => {
        const year = new Date().getFullYear();
        return Array.from(
          { length: back },
          (v, i) => year - back + i + 1
        ).reverse();
      };

      return years(100);
    },
    selectDate() {
      this.dateSelectedFinal = this.formatDateString(
        this.daySelected,
        this.monthSelected,
        this.yearSelected,
        this.blockSelected
      );
    },
    setErrorLogin(error) {
      if (!error) {
        this.errorLogin = [];
      } else {
        this.errorLogin.push(error);
      }
    },
    reservation() {
      let continueReservation = () => {
        PurchaseService.purchaseInit({
          doctor: this.doctor.doctor.name,
          block_id: this.blockSelected.id,
        })
          .then(response => {
            window.location.href = response.data;
          })
          .catch(err => {
            console.log(err);
          });
      };

      if (this.payment == null) {
        return;
      }

      this.change(true);
      if (this.user && this.user.id) {
        continueReservation();
      } else {
        if (this.login_bool) {
          if (!this.canSubmitLogin) {
            return;
          }
          // Validation
          this.setErrorLogin();
          if (!this.validateEmail(this.login_data.email)) {
            this.setErrorLogin('Correo no válido!');
            this.change(false);
            return;
          }
          // Login Call
          this.canSubmitLogin = false;
          this.login({
            email: this.login_data.email,
            password: this.login_data.password,
          })
            .then(() => {
              continueReservation();
            })
            .catch(() => {
              this.change(false);
              this.setErrorLogin('Correo o Contraseña incorrectas!');
            })
            .finally(() => {
              this.canSubmitLogin = true;
            });
        } else {
          if (!this.canSubmitRegister) {
            return;
          }

          // Validation
          this.setErrorRegister();
          if (!this.validateEmail(this.register_data.email)) {
            this.setErrorRegister('Correo no válido!');
            this.change(false);
            return;
          }
          if (!this.validateRut(this.register_data.rut)) {
            this.setErrorRegister('Rut no válido!');
            this.change(false);
            return;
          }
          if (
            this.register_data.password !== this.register_data.confirmpassword
          ) {
            this.setErrorRegister('Contraseñas no son iguales!');
            this.change(false);
            return;
          }
          if (
            this.register_data.phone !== this.register_data.phone_confirmation
          ) {
            this.setErrorRegister('Números de teléfono no son iguales!');
            this.change(false);
            return;
          }
          if (!this.register_data.terms) {
            this.setErrorRegister('Debes Aceptar los Términos y Condiciones!');
            this.change(false);
            return;
          }
          if (!this.form.birthdate) {
            this.setErrorRegister('Debes ingresar tu fecha de nacimiento!');
            this.change(false);
            return;
          }

          if (!this.form.prevision) {
            this.setErrorRegister('Debes ingresar tu Previsión de salud!');
            this.change(false);
            return;
          }

          if (
            !this.register_data.comuna ||
            !this.register_data.region ||
            this.register_data.address == ''
          ) {
            this.setErrorRegister('Debes completar tu dirección de factura!');
            this.change(false);
            return;
          }

          // Login Call
          this.canSubmitRegister = false;
          this.register({
            names: this.register_data.names,
            lastnames: this.register_data.lastnames,
            phone: this.country.dialCode + this.register_data.phone,
            phone_confirmation:
              this.country.dialCode + this.register_data.phone_confirmation,
            rut: this.register_data.rut,
            birthdate: this.form.birthdate,
            region: this.register_data.region,
            comuna: this.register_data.comuna,
            address: this.register_data.address,
            health: this.form.prevision,
            email: this.register_data.email,
            password: this.register_data.password,
            password_confirmation: this.register_data.confirmpassword,
          })
            .then(() => {
              continueReservation();
            })
            .catch(() => {
              this.setErrorRegister('Correo o Contraseña incorrectas!');
              this.change(false);
            })
            .finally(() => {
              this.canSubmitRegister = true;
            });
        }
      }
    },
    leftArrow() {
      let monthSelected = this.monthSelected;
      let yearSelected = this.yearSelected;
      if (this.calendarType == 1) {
        if (monthSelected == 1) {
          monthSelected = 12;
          yearSelected -= 1;
        } else {
          monthSelected -= 1;
        }
        let date = moment(
          yearSelected.toString() +
            '-' +
            (monthSelected.toString().length == 1 ? '0' : '') +
            monthSelected.toString() +
            '-01' +
            ' 01:00',
          'YYYY-MM-DD HH:mm'
        );
        date = moment(
          date.year().toString() +
            '-' +
            (date.toString().length == 1 ? '0' : '') +
            (date.month() + 1).toString() +
            '-' +
            date.daysInMonth().toString() +
            ' 01:00',
          'YYYY-MM-DD HH:mm'
        );
        if (date.isSameOrAfter(moment())) {
          this.monthSelected = monthSelected;
          this.yearSelected = yearSelected;
          this.daySelected = null;
        }
      }
      if (this.calendarType == 2 && this.yearSelected > moment().year()) {
        this.yearSelected -= 1;
        this.monthSelected = null;
        this.daySelected = null;
      }
    },
    rightArrow() {
      if (this.calendarType == 1) {
        if (this.monthSelected == 12) {
          this.monthSelected = 1;
          this.yearSelected += 1;
        } else {
          this.monthSelected += 1;
        }
        this.daySelected = null;
      }
      if (this.calendarType == 2) {
        this.yearSelected += 1;
        this.monthSelected = null;
        this.daySelected = null;
      }
    },
    createMonth(month, year) {
      let date = moment(
        year.toString() +
          '-' +
          (month.toString().length == 1 ? '0' : '') +
          month.toString() +
          '-' +
          '01 ' +
          '01:00',
        'YYYY-MM-DD HH:mm'
      );
      moment.locale('es');
      date.locale(false);
      let nullsLeft = date.weekday();
      let monthArray = [[]];
      for (let i = 0; i < nullsLeft; i++) {
        monthArray[monthArray.length - 1].push(null);
      }
      for (let i = 0; i < date.daysInMonth(); i++) {
        if (monthArray[monthArray.length - 1].length < 7) {
          if (
            i + 1 < moment().date() &&
            month == moment().month() + 1 &&
            year == moment().year()
          ) {
            monthArray[monthArray.length - 1].push({
              number: i + 1,
              valid: false,
            });
          } else {
            monthArray[monthArray.length - 1].push({
              number: i + 1,
              valid: true,
            });
          }
        } else {
          if (
            i + 1 < moment().date() &&
            month == moment().month() + 1 &&
            year == moment().year()
          ) {
            monthArray.push([{ number: i + 1, valid: false }]);
          } else {
            monthArray.push([{ number: i + 1, valid: true }]);
          }
        }
      }
      return monthArray;
    },
    selectDay(day) {
      if (day != null && day.valid && this.daysArray.includes(day.number)) {
        this.daySelected = day.number;
      }
    },
    formatDate(day, month, year, block) {
      if (block == null || day == null || month == null || year == null) {
        return '';
      }
      let date = moment(
        year.toString() +
          '-' +
          (month.toString().length == 1 ? '0' : '') +
          month.toString() +
          '-' +
          day.toString() +
          ' ' +
          moment(block.start_date, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        'YYYY-MM-DD HH:mm'
      );
      moment.locale('es');
      date.locale(false);
      return date.format('LLLL');
    },
    formatDateHour(day, month, year, block) {
      if (block == null || day == null || month == null || year == null) {
        return '';
      }
      let date = moment(
        year.toString() +
          '-' +
          (month.toString().length == 1 ? '0' : '') +
          month.toString() +
          '-' +
          day.toString() +
          ' ' +
          moment(block.start_date, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        'YYYY-MM-DD HH:mm'
      );
      moment.locale('es');
      date.locale(false);
      return date.format('HH:mm');
    },
    formatDateNoHour(day, month, year) {
      if (day == null || month == null || year == null) {
        return '';
      }
      let date = moment(
        year.toString() +
          '-' +
          (month.toString().length == 1 ? '0' : '') +
          month.toString() +
          '-' +
          day.toString() +
          ' ' +
          '00:00',
        'YYYY-MM-DD HH:mm'
      );
      moment.locale('es');
      date.locale(false);
      return (
        date.format('LLLL').split('de')[0] +
        ' de ' +
        date.format('LLLL').split('de')[1]
      );
    },
    formatDateString(day, month, year, block) {
      if (block == null || day == null || month == null || year == null) {
        return null;
      }
      let date = moment(
        year.toString() +
          '-' +
          (month.toString().length == 1 ? '0' : '') +
          month.toString() +
          '-' +
          day.toString() +
          ' ' +
          moment(block.start_date, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        'YYYY-MM-DD HH:mm'
      );
      moment.locale('es');
      date.locale(false);
      return date.format('YYYY-MM-DD HH:mm');
    },
    formatMonth(month, year) {
      if (month == null || year == null) {
        return '';
      }
      let date = moment(
        year.toString() +
          '-' +
          (month.toString().length == 1 ? '0' : '') +
          month.toString() +
          '-' +
          '01 ' +
          '01:00',
        'YYYY-MM-DD HH:mm'
      );
      moment.locale('es');
      date.locale(false);
      return date.format('MMMM YYYY');
    },
  },
  watch: {
    'login_data.email'() {
      if (this.login_data.email != '') {
        this.errorEmailLogin = !this.validateEmail(this.login_data.email);
      } else {
        this.errorEmailLogin = false;
      }
    },
    'register_data.email'() {
      if (this.register_data.email != '') {
        this.errorEmailRegister = !this.validateEmail(this.register_data.email);
      } else {
        this.errorEmailRegister = false;
      }
    },
    'register_data.rut'() {
      if (this.register_data.rut != '') {
        this.errorRut = !this.validateRut(this.register_data.rut);
      } else {
        this.errorRut = false;
      }
    },
    '$route.params.doctor': {
      handler: function(doctor) {
        this.change(true);
        this.doctor = null;
        DoctorService.getDoctor(doctor)
          .then(response => {
            this.change(false);
            this.doctor = response.data;
            this.changeDaysArray();
            this.getSchedule();
          })
          .catch(() => {
            this.$router.replace('/');
          });
      },
      deep: true,
      immediate: true,
    },
    'register_data.region'() {
      this.comunas = this.regiones.find(
        e => e.region === this.register_data.region
      )
        ? this.regiones.find(e => e.region === this.register_data.region)
            .comunas
        : [];
    },
    'form.day'() {
      if (
        this.form.day != '' &&
        this.form.month != '' &&
        this.form.year != ''
      ) {
        this.form.birthdate =
          this.form.day + '/' + this.form.month + '/' + this.form.year;
      }
    },
    'form.month'() {
      this.form.day = '';
    },
    'form.year'() {
      this.form.month = '';
    },
    daySelected() {
      this.blockSelected = null;
      if (this.daySelected) {
        this.getSchedule();
      }
    },
    yearSelected() {
      this.changeDaysArray();
    },
    monthSelected() {
      this.changeDaysArray();
    },
  },
  computed: {
    ...mapGetters({
      isauthenticated: ['authentication/isauthenticated'],
      loadingStore: ['loading/isloading'],
      user: ['authentication/user'],
    }),
    months() {
      if (this.form.year != '') {
        if (parseInt(this.form.year) == moment().year()) {
          let array = [];
          let selection = moment.monthsShort();
          for (let i = 0; i <= moment().month(); i++) {
            array.push(selection[i]);
          }
          return array;
        } else {
          return moment.monthsShort();
        }
      }
      return [];
    },
    days_list() {
      if (this.form.month != '' && this.form.year != '') {
        let dayInMonth = moment(
          this.form.year + '-' + this.form.month,
          'YYYY-MM'
        ).daysInMonth();
        let array = [];
        for (let i = 1; i <= dayInMonth; i++) {
          array.push(i);
        }
        return array;
      }
      return [];
    },
    age() {
      if (this.form.birthdate) {
        let date = moment(this.form.birthdate, 'DD/MM/YYYY');
        let years = moment().diff(date, 'years', false);
        let months = moment().diff(date, 'months', false);
        return (
          years +
          ' año' +
          (years != 1 ? 's' : '') +
          ' y ' +
          (months - 12 * years) +
          ' mes' +
          (months - 12 * years != 1 ? 'es' : '')
        );
      }
      return null;
    },
    dateSelected() {
      return this.formatDate(
        this.daySelected,
        this.monthSelected,
        this.yearSelected,
        this.blockSelected
      );
    },
    dateSelectedNoHour() {
      return this.formatDateNoHour(
        this.daySelected,
        this.monthSelected,
        this.yearSelected
      );
    },
    dateSelectedHour() {
      return this.formatDateHour(
        this.daySelected,
        this.monthSelected,
        this.yearSelected,
        this.blockSelected
      );
    },
    monthyearSelected() {
      return this.formatMonth(this.monthSelected, this.yearSelected);
    },
    month() {
      return this.createMonth(this.monthSelected, this.yearSelected);
    },
    previousMonthIsBlocked() {
      let monthSelected = this.monthSelected;
      let yearSelected = this.yearSelected;
      if (monthSelected == 1) {
        monthSelected = 12;
        yearSelected -= 1;
      } else {
        monthSelected -= 1;
      }
      let date = moment(
        yearSelected.toString() +
          '-' +
          (monthSelected.toString().length == 1 ? '0' : '') +
          monthSelected.toString() +
          '-01' +
          ' 01:00',
        'YYYY-MM-DD HH:mm'
      );
      date = moment(
        date.year().toString() +
          '-' +
          (date.toString().length == 1 ? '0' : '') +
          (date.month() + 1).toString() +
          '-' +
          date.daysInMonth().toString() +
          ' 01:00',
        'YYYY-MM-DD HH:mm'
      );
      return !date.isSameOrAfter(moment());
    },
    monthsShort() {
      let months = moment.monthsShort();
      moment.locale('es');
      if (this.yearSelected == moment().year()) {
        for (let i = 0; i < moment().month(); i++) {
          months[i] = null;
        }
      }
      return months;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
.min-height {
  min-height: 80vh;
}
.fadeHeight-enter-active {
  transition: all 0.1s;
  max-height: 230px;
}
.fadeHeight-leave-active {
  transition: all 0s;
  max-height: 230px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
.fadeHeightNormal-enter-active {
  transition: all 0.5s;
  max-height: 230px;
}
.fadeHeightNormal-leave-active {
  transition: all 0.5s;
  max-height: 230px;
}
.fadeHeightNormal-enter,
.fadeHeightNormal-leave-to {
  opacity: 0;
  max-height: 0px;
}
.fadeHeightSecond-enter-active {
  transition: all 0.5s;
  max-height: 800px;
}
.fadeHeightSecond-leave-active {
  transition: all 0.5s;
  max-height: 800px;
}
.fadeHeightSecond-enter,
.fadeHeightSecond-leave-to {
  opacity: 0;
  max-height: 0px;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 0s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.no-margin {
  margin-bottom: 0px !important;
  margin-top: 9px !important;
}
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #10b981;
  border-color: #069669;
  border-width: 1px;
}
.login-forgot {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  text-align: right;
}
@media only screen and (max-width: 900px) {
  .login-forgot {
    font-size: 12px;
  }
}
@media only screen and (min-width: 901px) {
  .login-forgot {
    font-size: 14px;
  }
}
.login-link {
  cursor: pointer;
  color: #111827;
  text-decoration: underline;
}
</style>
